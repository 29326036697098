import {
  Instance_default
} from "./chunk-DUBY2QKT.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/Cms/Categories/CMSCategoriesRoute.ts
var CmsCategoriesRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "";
    this.getList = (filters) => {
      const buildFilters = this.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "category_with_org_check_metaverse_categories_63",
          ...buildFilters
        })
      );
    };
    this.getDetail = (id = 0) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "category_with_org_check_metaverse_categories_63",
          id
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "reditem",
          view: "category_with_org_check_metaverse_categories_63"
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "reditem",
          view: "category_with_org_check_metaverse_categories_63"
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.delete = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: "reditem",
          view: "category_with_org_check_metaverse_categories_63"
        }),
        {
          data: { ids: Array.isArray(ids) ? ids : [ids] }
        }
      );
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
  }
};
var CMSCategoriesRoute_default = CmsCategoriesRoute;

export {
  CMSCategoriesRoute_default
};
